import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../components/login/User';
import menuReducer from './../components/menu/menu';
import mandantPermissionReducer from '../components/user/permissions/permissionStates/MandantPermission';
import userPermission from '../components/user/permissions/permissionStates/UserPermission'; 
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    version: 1,
    storage, 
}

const reducer = combineReducers({
    user: userReducer,
    menu: menuReducer,
    userPermission: userPermission,
})

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
    reducer: persistedReducer,
}); 

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch