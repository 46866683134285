import { logout } from "./HandleLogout";

export const refresh = async () => {

    try {

        const response = await fetch(process.env.REACT_APP_BASE_URL + "/api/token/refresh", {
            method: "POST",
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            }
        }).then(async (response) => {
            if(response.ok){
                const data = await response.json();

                await localStorage.setItem('token', data.token);

                return data.token;
            } else {
                console.log("[Refresh Token Error]: ", response.statusText);
                logout();
                return null;
            }
        });
    } catch (error) {
        return error;
    } 
}