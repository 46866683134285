import React from 'react'
import { useAppDispatch } from '../hooks'
import {invalidate} from './InvalidateCookie'
import { resetAll } from '../components/user/permissions/permissionStates/UserPermission'
import { setLoggedOut } from '../components/login/User'
import { store } from '../redux/store'

export const logout = () => {

    invalidate().then(() => {
        localStorage.removeItem("token");
        store.dispatch(resetAll());
        store.dispatch(setLoggedOut());
    })

}