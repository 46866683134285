import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { store } from './redux/store';
import { client } from './graphql/client';
import { Provider } from 'react-redux'; 
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { SearchDriver } from "@elastic/search-ui";
import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import './components/menu/leftbar/submenu/LeftBarSubmenu.css'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
); 

let persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
        
          <BrowserRouter>
          <ApolloProvider client={client}>
          <App />
          </ApolloProvider>

          </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
