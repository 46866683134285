import { Routes, Route } from "react-router-dom";
import React from "react";

// redux
import { RootState } from "../redux/store";
import { useAppSelector } from "../hooks";

const MandantHome = React.lazy(() => import('../components/mandant/MandantDashboard'));
const AddMandant = React.lazy(() => import('./../components/mandant/AddMandant'));
const MandantBills = React.lazy(() => import('../components/mandant/tables/BillTableAll'));
const MandantContracts = React.lazy(() => import('../components/mandant/tables/ContractTableAll'));
const MandantMore = React.lazy(() => import('../components/mandant/detailtabs/MandantDetailDashboard'));
const MandantInfo =  React.lazy(() => import('../components/mandant/detailtabs/DisplayMandant'));
const MandantAdress =  React.lazy(() => import('../components/mandant/detailtabs/Address'));
const MandantEpot =  React.lazy(() => import('../components/e-pot/Epot'));
const AddMandantEpot =  React.lazy(() => import('../components/e-pot/AddEpot'));
const MandantTenants =  React.lazy(() => import('../components/tenant/Tenants'));
const AddMandantTenants =  React.lazy(() => import('../components/tenant/AddTenant'));
const MandantMoreBills =  React.lazy(() => import('../components/mandant/detailtabs/report/Report'));
const MandantMoreContracts =  React.lazy(() => import('../components/mandant/detailtabs/contract/Contracts'));
const ViewEpotDetail =  React.lazy(() => import('../components/e-pot/DisplayEpot'));
const ViewTenantDetail =  React.lazy(() => import('../components/tenant/DisplayTenant'));
const DisplayPotties =  React.lazy(() => import('../components/e-pot/PottiesDisplay'));
const AddPottie =  React.lazy(() => import('../components/e-pot/AddPottie'));
const DisplayPottie =  React.lazy(() => import('../components/e-pot/DisplayPottie'));
const AddBills =  React.lazy(() => import('../components/mandant/detailtabs/report/AddReport'));
const AddContracts =  React.lazy(() => import('../components/mandant/detailtabs/contract/AddContracts'));
const DisplayContracts = React.lazy(() => import('../components/mandant/detailtabs/contract/DisplayContract'));
const DisplayReport = React.lazy(() => import('../components/mandant/detailtabs/report/DisplayReport'));



function Mandant(){

    const canWriteAll = useAppSelector((state: RootState) => state.userPermission.mandant.write_all);
    const canReadSelf = useAppSelector((state: RootState) => state.userPermission.mandant.read_self);
    const canWriteSelf = useAppSelector((state: RootState) => state.userPermission.mandant.write_self);

    return(
        <>
        <Routes>
            <Route index element={<MandantHome />}/>

            {   canWriteAll     ? 
            <Route path="add" element={<AddMandant />}/>
            : <></>     }
            
            <Route path="bills" element={<MandantBills />}/>
            <Route path="contracts" element={<MandantContracts />}/>

            {   canReadSelf ? 
            <Route path="details" element={<MandantMore/>}>
                <Route path="info" element={<MandantInfo/>}/>
                <Route path="address" element={<MandantAdress/>}/> 
                <Route path="epot" element={<MandantEpot
                canWriteAll={canWriteSelf}/>}/>
                <Route path="tenant" element={<MandantTenants
                canEdit={canWriteSelf}/>}/>
                <Route path="bills" element={<MandantMoreBills/>}/>
                <Route path="contracts" element={<MandantMoreContracts/>}/>
            </Route>
            : <></>}


            {   canWriteSelf ?
            <>
                <Route path="details/epot/add" element={<AddMandantEpot/>}/>
                <Route path="details/tenant/add" element={<AddMandantTenants/>}/>
                <Route path="details/epot/potties/add" element={<AddPottie/>}/>
                <Route path="details/bills/add" element={<AddBills/>}/>
                <Route path="details/contracts/add" element={<AddContracts/>}/>
            </> 
            : <></>}

            {
                canReadSelf ? 
            <>
                <Route path="details/epot/view" element={<ViewEpotDetail/>}/>
                <Route path="details/tenant/view" element={<ViewTenantDetail/>}/>
                <Route path="details/epot/potties" element={<DisplayPotties/>}/>
                <Route path="details/epot/potties/view" element={<DisplayPottie canEdit={canWriteSelf}/>}/>
                <Route path="details/bills/view" element={<DisplayReport/>}/>
                <Route path="details/contracts/view" element={<DisplayContracts/>}/>
            </>
                : <></>
            }
        </Routes>
        </>
    );
}

export default Mandant;