
export const invalidate = async () => {
   
    const response = await fetch(process.env.REACT_APP_BASE_URL + "/api/token/invalidate", {
        method: "POST",
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response;
    
}

