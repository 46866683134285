import React from 'react';
import './App.css';
import logo from './logo.png';
import { Routes, Route, Navigate } from 'react-router-dom';
import type { RootState } from './redux/store';
import { connect, ConnectedProps } from 'react-redux'
import { setLoggedIn, setLoggedOut } from './components/login/User';
import BuildingData from './components/building/forms/BuildingData';
import { GET_STUFF } from './graphql/query';
import { Dispatch } from 'redux';
import { startTransition } from 'react';
import { UserPermissionState } from './components/user/UserTypes';
import Mandant from './routes/Mandant';
import TokenManager from './utils/TokenManager';

interface Props {
  permissions: UserPermissionState;
  loggedIn: boolean;
  setLoggedIn: () => void;
  setLoggedOut: () => void;
}

const mapStateToProps = (state: RootState) => ({
  permissions: state.userPermission,
  loggedIn: state.user.loggedIn,
  userId: state.user.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ 
  setLoggedOut: () => dispatch(setLoggedOut()),
  setLoggedIn: () => dispatch(setLoggedIn())
});

const Dashboard = React.lazy(() => import('./routes/Dashboard'));
const Login = React.lazy(() => import('./routes/Login'));
const Main = React.lazy(() => import('./routes/Main'));
const User = React.lazy(() => import('./routes/user/User'));
//const Mandant = React.lazy(() => import('./routes/Mandant'));
const Building = React.lazy(() => import('./routes/Building'));
const Charger = React.lazy(() => import('./routes/Charger'));
const Partner = React.lazy(() => import('./routes/Partner'));
const Search = React.lazy(() => import('./components/search/Search'));

class App extends React.Component<Props> {

  state = {
    loading: false,
  }

  public async componentDidMount(): Promise<void> {
    
    const token = localStorage.getItem('token');

    if (!token) {
      this.props.setLoggedOut();
      this.setState({loading: false});
    }  
    
  }

  render() {
    
    let { loggedIn } = this.props;
    let  permissions  = this.props.permissions;
  
    return (
      this.state.loading ? <div>loading</div> : 
      <React.Suspense fallback={<Main/>}>
        <Routes>
          <Route path='/' element={ loggedIn ? <Main/> : <Login/> }>     
          <Route index element={<Dashboard />} />
          <Route path='dashboard/*' element={<Dashboard/>}/>

          <Route path='search/*' element={<Search/>}/>
          
          {!permissions.building.read_all&&!permissions.building.read_self ? <></> : <Route path="building/*" element={<Building/>}/>}
          {!permissions.charger.read_all&&!permissions.charger.read_self ? <></> : <Route path="charger/*" element={<Charger/>} />}
          {!permissions.partner.read_all&&!permissions.partner.read_self ? <></> : <Route path="partner/*" element={<Partner/>}/>}
          {(permissions.user_info.read_all || permissions.user_info.read_self) ? <Route path='user/*'element={<User/>}/> : <></>}
          {(permissions.mandant.read_all || permissions.mandant.read_self) ? <Route path='mandant/*' element={<Mandant/>}/>: <></>}

          </Route>
          {<Route path="*" element={<Navigate to="/dashboard" />} />}
          

        </Routes>
        
        
      </React.Suspense>
    );
    

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
