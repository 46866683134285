import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface menuState {
    leftNavbar: boolean
}

const initialState: menuState = {
    leftNavbar: false
}
//counterSlice (?) 
export const counterSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        toggleMenu: (state) => {
            state.leftNavbar = !state.leftNavbar;
        },
    },
});

export const { toggleMenu } = counterSlice.actions;

export default counterSlice.reducer;

export type CounterState = ReturnType<typeof counterSlice.reducer>;
