import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../redux/store'

interface userState {
    loggedIn: boolean;
    id: string;
}

const initialState: userState = {
    loggedIn: false,
    id: "null"
}
//counterSlice (?) 
export const counterSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLoggedIn: (state) => {
            state.loggedIn = true;
        },
        setLoggedOut: (state) => {

            state.loggedIn = false;
        },
        setId: (state, action) => {
            
            state.id = action.payload;
        },

    },
});

export const { setLoggedIn, setLoggedOut, setId } = counterSlice.actions;

export default counterSlice.reducer;

export type CounterState = ReturnType<typeof counterSlice.reducer>;
