import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserPermissionState } from '../../UserTypes'

const initialState: UserPermissionState = {
    user_info: {
        read_self: false,
        read_all: false,
        write_self: false,
        write_all: false,
    },
    mandant: {
        read_self: false,
        read_all: false,
        write_self: false,
        write_all: false,
    },
    partner: {
        read_self: false,
        read_all: false,
        write_self: false,
        write_all: false,
    },
    tenant: {
        read_self: false,
        read_all: false,
        write_self: false,
        write_all: false,
    },
    permission: {
        read_self: false,
        read_all: false,
        write_self: false,
        write_all: false,
    },
    building: {
        read_self: false,
        read_all: false,
        write_self: false,
        write_all: false,
    },
    charger: {
        read_self: false,
        read_all: false,
        write_self: false,
        write_all: false,
    },
}

export const counterSlice = createSlice({
    name: "userPermission",
    initialState,
    reducers: {
        setRS: (state, action: PayloadAction<string>) => {
            const key = action.payload;

            switch (key) {
                case 'user_info':
                    state.user_info.read_self = true;
                break;
                case 'mandant':
                    state.mandant.read_self = true;
                break;
                case 'tenant':
                    state.tenant.read_self = true;
                break;
                case 'partner':
                    state.partner.read_self = true;
                break;
                case 'permission':
                    state.permission.read_self = true;
                break;
                case 'building':
                    state.building.read_self = true;
                break;
                case 'charger':
                    state.charger.read_self = true;
                break;
                default:
                break;
            }
        },
        setRA: (state, action: PayloadAction<string>) => {
            const key = action.payload;
            switch (key) {
                case 'user_info':
                    state.user_info.read_all = true;
                break;
                case 'mandant':
                    state.mandant.read_all = true;
                break;
                case 'tenant':
                    state.tenant.read_all = true;
                break;
                case 'partner':
                    state.partner.read_all = true;
                break;
                case 'permission':
                    state.permission.read_all = true;
                break;
                case 'building':
                        state.building.read_all = true;
                    break;
                case 'charger':
                    state.charger.read_all = true;
                break;
                default:
                break;
        }
        },
        setWS: (state, action: PayloadAction<string>) => {
            const key = action.payload;
            switch (key) {
                case 'user_info':
                    state.user_info.write_self = true;
                break;
                case 'mandant':
                    state.mandant.write_self = true;
                break;
                case 'tenant':
                    state.tenant.write_self = true;
                break;
                case 'partner':
                    state.partner.write_self = true;
                break;
                case 'permission':
                    state.permission.write_self = true;
                break;
                case 'building':
                        state.building.write_self = true;
                    break;
                case 'charger':
                    state.charger.write_self = true;
                break;
                default:
                break;                  
        }
        },
        setWA: (state, action: PayloadAction<string>) => {
            const key = action.payload;
            switch (key) {
                case 'user_info':
                    state.user_info.write_all = true;
                break;
                case 'mandant':
                    state.mandant.write_all = true;
                break;
                case 'tenant':
                    state.tenant.write_all = true;
                break;
                case 'partner':
                    state.partner.write_all = true;
                break;
                case 'permission':
                    state.permission.write_all = true;
                break;
                case 'building':
                        state.building.write_all = true;
                    break;
                case 'charger':
                    state.charger.write_all = true;
                break;
                default:
                break;
        }

        },
        resetAll : (state) => {
            return initialState;
        }
    },
});

export const { setRS , setWS , setRA , setWA, resetAll } = counterSlice.actions;

export default counterSlice.reducer;

export type CounterState = ReturnType<typeof counterSlice.reducer>;

