import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { refresh } from '../utils/Refresh';
import {logout} from '../utils/HandleLogout'; 

// configuration of the client: concat the different links 

const httpLink = new HttpLink({
    uri:process.env.REACT_APP_BASE_URL+'/api/graphql',
});

const authLink = setContext((_, { headers }) => {
  let token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const afterWareLink = onError(({operation, forward,  response, graphQLErrors , networkError = {} as any}) => {
  const status: number = networkError && networkError.statusCode ? networkError.statusCode : null;
  if(graphQLErrors){
    graphQLErrors.map(({message, locations, path}) => {
      console.log(`[graphQLError]: ${message}`);
    });
  } else if(networkError){

    if (status === 401){


      refresh().then((token) => {
        let context = operation.getContext();

        operation.setContext({
          headers: {
            ...context.headers,
            Authorization: `Bearer ${token}`,
          },
        });
        return forward(operation);
      });

  
    } else if (status === 200){
  
      console.log("[Network Error]: ", networkError.statusCode);
  
    } else {
  
      console.log("[Network Error]: ", networkError.statusCode);
      console.log("[Network Error]: ", networkError);
      logout();

      return networkError;
  
    }    
  }
});
  
export const client = new ApolloClient({
  link: afterWareLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

